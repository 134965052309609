import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import {EncrDecrService} from "../_services/encr-decr.service";
import {LoaderService} from "../shared/loader/loader.service";
@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private encDecService: EncrDecrService, private loaderService:LoaderService) { }


  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.setloader(true); // loader open when http request is opened
    let headerdata = {
      'Content-Type': 'application/json'
    };
    if (localStorage.getItem('_token')) {
      headerdata['x-access-token'] = localStorage.getItem('_token');
    }
    let cloneRequest = {
      setHeaders: headerdata
    };
    let requestData = req.body;
    if (!localStorage.getItem('_lang')) {
      localStorage.setItem('_lang', 'EN');
    }
    if (requestData) {
      requestData['lang'] = localStorage.getItem('_lang');
    }

    if (req.url.indexOf('/nonsecure/') === -1 && req.url.indexOf('/assets/') === -1 && req.body) {
      cloneRequest['body'] = {
        'param': this.encDecService.set(JSON.stringify(req.body))
      }
    }
    let tokenizedReq = req.clone(cloneRequest);
    return next.handle(tokenizedReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.loaderService.setloader(false); // close loader after response
          if (req.url.indexOf('/nonsecure/') === -1 && req.url.indexOf('/assets/') === -1) {
            let responseBody = event.body;
            responseBody['data'] = JSON.parse(this.encDecService.get(event.body.data));
            delete responseBody['body'];
            event = event.clone({
              body: responseBody
            })
          }
        } 
        return event;
      })

    );

  }

}
