import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contactUs'
})
export class ContactUsPipe implements PipeTransform {

  transform(value: any,args?: any): any {
    let str = "";
    if (value) {
      str = value.replaceAll(' - ', '<br>');
      let lang = localStorage.getItem('_lang');
      if (lang == 'AR') {
        str = str.replace('Address', 'العنوان');
        str = str.replace('Email', 'البريد الإلكتروني');
        str = str.replace('Phone', 'رقم التواصل');
      }
    }
    return str;
  }

}
