import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class GlobalConstantService {

	constructor(
	) { }

	BASE_URL: string = environment.base_url;
	API_URL: string = environment.api_url;


	public apiModules: any = {
		action: {
			url: environment.api_url + '/action',
			methods: [
				{ name: 'login', type: 'post', url: '',method:'LOGIN' },
				{ name: 'signup_content', type: 'post', url:'', method:'SIGNUP_CONTENT'},
				{ name: 'city_by_country', type: 'post', url:'', method:'CITY_LIST'},
				{ name: 'signup_action', type: 'post', url:'', method:'SIGNUP'},
				{ name: 'signup_verify_action', type: 'post', url:'', method:'VERIFICATION'},
				{ name: 'home_page', type: 'post', url:'', method:'HOME_PAGE_CONTENT'},
				{ name: 'header_info', type: 'post', url:'', method:'HEADER_CONTENT'},
				{ name: 'change_password', type: 'post', url:'', method:'PASSWORD_CHANGE'},
				{ name: 'forget_password_send', type: 'post', url:'', method:'PASSWORD_RESET_REQUEST'},
				{ name: 'forget_password_reset_action', type: 'post', url:'', method:'PASSWORD_RESET_ACTION'},
				{ name: 'cms_details', type: 'post', url:'', method:'CMS_DETAILS'},
				{ name: 'site_settings', type: 'post', url:'', method:'SITE_SETTINGS'},
				{ name: 'topic_list', type: 'post', url:'', method:'TOPIC_LIST'},
				{ name: 'topic_details', type: 'post', url:'', method:'TOPIC_DETAILS'},
				{ name: 'article_details', type: 'post', url:'', method:'ARTICLE_DETAILS'},
				{ name: 'edit_profile_content', type: 'post', url:'', method:'EDIT_PROFILE_PAGE_CONTENT'},
				{ name: 'edit_profile_action', type: 'post', url:'', method:'EDIT_PROFILE'},
				{ name: 'event_list', type: 'post', url:'', method:'EVENT_LIST'},
				{ name: 'event_details', type: 'post', url:'', method:'EVENT_DETAILS'},
				{ name: 'book_list', type: 'post', url:'', method:'BOOK_LIST'},
				{ name: 'book_details', type: 'post', url: '', method: 'BOOK_DETAILS' },
				{ name: 'book_details_by_id', type: 'post', url:'', method:'BOOK_DETAILS_BY_ID'},
				{ name: 'webinar_details', type: 'post', url:'', method:'WEBINAR_DETAILS'},
				{ name: 'advertisement_list', type: 'post', url:'', method:'ADVERTISEMENT_LIST'},
				{ name: 'catelog_list', type: 'post', url:'', method:'CATELOG_LIST'},

				{ name: 'insert_cart', type: 'post', url:'', method:'INSERT_CART'},
				{ name: 'cart_details', type: 'post', url:'', method:'CART_DETAILS'},
				{ name: 'cart_update', type: 'post', url:'', method:'CART_UPDATE'},
				{ name: 'cart_delete', type: 'post', url:'', method:'CART_DELETE'},
				{ name: 'shipping_billing_address_content', type: 'post', url:'', method:'SHIPPING_BILLING_ADDRESS_CONTENT'},
				{ name: 'save_shipping_billing_address', type: 'post', url:'', method:'SAVE_SHIPPING_BILLING_ADDRESS'},
				{ name: 'order_preview', type: 'post', url:'', method:'ORDER_PREVIEW'},
				{ name: 'shipping_billing_address_details', type: 'post', url:'', method:'SHIPPING_BILLING_ADDRESS_DETAILS'},
				{ name: 'apply_coupon', type: 'post', url:'', method:'APPLY_COUPON'},
				{ name: 'user_card_list', type: 'post', url:'', method:'USER_CARD_LIST'},
				
				{ name: 'place_your_order', type: 'post', url:'', method:'PLACE_YOUR_ORDER'},
				{ name: 'pay_process', type: 'post', url:'', method:'PAY_INITIAL'},
				{ name: 'order_list', type: 'post', url:'', method:'ORDER_LIST'},
				{ name: 'order_details', type: 'post', url:'', method:'ORDER_DETAILS'},
				{ name: 'pay_success', type: 'post', url:'', method:'PAYMENT_STATUS'},

				
				{ name: 'test_list', type: 'post', url:'', method:'TEST_LIST'},
				{ name: 'training_list', type: 'post', url:'', method:'TRAINIING_LIST'},
				{ name: 'center_list', type: 'post', url:'', method:'CENTER_LIST'},
				{ name: 'test_date_list', type: 'post', url:'', method:'TEST_DATE_LIST'},
				{ name: 'training_date_list', type: 'post', url:'', method:'TRAINING_DATE_LIST'},
				{ name: 'test_center_list', type: 'post', url:'', method:'TEST_CENTER_LIST'},
				{ name: 'training_center_list', type: 'post', url:'', method:'TRAINING_CENTER_LIST'},
				{ name: 'country_list', type: 'post', url:'', method:'COUNTRY_LIST'},
				{ name: 'state_list', type: 'post', url:'', method:'STATE_LIST'},
				{ name: 'city_list_by_state', type: 'post', url:'', method:'CITY_LIST_BY_STATE'},
				{ name: 'place_test_or_training_order', type: 'post', url:'', method:'PLACE_TEST_OR_TRAINING_ORDER'},
				{ name: 'test_or_training_booking_list', type: 'post', url:'', method:'TEST_OR_TRAINING_BOOKING_LIST'},
				{ name: 'search_list', type: 'post', url:'', method:'SEARCH_LIST'},
				{ name: 'place_event_order', type: 'post', url:'', method:'PLACE_EVENT_ORDER'},

				{ name: 'contact_store', type: 'post', url:'', method:'CONTACT_STORE'},
				
				{ name: 'blog_category_list', type: 'post', url:'', method:'BLOG_CATEGORY_LIST'},
				{ name: 'blog_list', type: 'post', url:'', method:'BLOG_LIST'},
				{ name: 'blog_details', type: 'post', url:'', method:'BLOG_DETAILS'},
				
				{ name: 'book_store_page_content', type: 'post', url:'', method:'BOOK_STORE_PAGE_CONTENT'},
				{ name: 'new_and_notable_book', type: 'post', url:'', method:'NEW_AND_NOTABLE_BOOK'},
				{ name: 'book_store_video', type: 'post', url:'', method:'BOOK_STORE_VIDEO'},
				{ name: 'top_selling_book', type: 'post', url:'', method:'TOP_SELLING_BOOK'},
				{ name:'related_book', type: 'post', url:'',method:'RELATED_BOOK'},
				{ name:'social_signup_action', type: 'post', url:'',method:'SOCIAL_SIGNUP_ACTION'},
				{ name:'educator_signup_action', type: 'post', url:'',method:'EDUCATOR_SIGNUP_ACTION'},
				{ name:'event_booking_list', type: 'post', url:'',method:'EVENT_BOOKING_LIST'},
				{ name: 'test_details', type: 'post', url:'', method:'TEST_DETAILS'},
				{ name: 'training_details', type: 'post', url:'', method:'TRAINIING_DETAILS'},
				{ name: 'shipping_city_by_country', type: 'post', url:'', method:'SHIPPING_CITY_LIST'},
				{ name: 'get_item_details', type: 'post', url:'', method:'GET_ITEM_DETAILS'},
				{ name: 'find_cart_item', type:'post',url:'',method:'FIND_CART_ITEM'},
				{ name: 'category_book_list', type:'post',url:'',method:'CATEGORY_BOOK_LIST'},
				{ name: 'announcement_store', type:'post',url:'',method:'RECIVE_ANNOUNCEMENT_STORE'},
				{ name: 'my_books', type: 'post', url:'', method:'MY_BOOKS'},
				{ name: 'read_my_book', type: 'post', url:'', method:'READ_MY_BOOK'},
				{ name: 'remove_reading', type: 'post', url: '', method: 'REMOVE_READING' },
				{ name: 'continue_read_my_book', type: 'post', url:'', method:'CONTINUE_READ_MY_BOOK'},
				{ name: 'check_stock_available', type: 'post', url:'', method:'CHECK_STOCK_AVAILABLE'},
			]
		},
		

	};
}
