import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { CommonService } from './../../_services/common.service';
import {Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {HttpRequestService} from './../../_services/http-request.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.scss']
})
export class AdvertiseComponent implements OnInit, OnChanges {
  @Input('config')config:any = {};
  advertiseList:Array<any> = [];
  constructor(private commonService: CommonService, private router: Router,public translate: TranslateService,		private httpServices:HttpRequestService,private toastr: ToastrService) {
    this.commonService.activelang.subscribe((lang) => {
      // this language will be used as a fallback when a translation isn't found in the current language
      translate.setDefaultLang(lang);

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translate.use(lang);
    });
  }
  ngOnInit() {
    this.getAdvertisementList();
  }
  ngOnChanges() {
    this.getAdvertisementList();

  }
  getAdvertisementList() {
    this.httpServices.setModule('action').post('advertisement_list',this.config).subscribe((response) => {
			
      if (response.status=='success') {
        this.advertiseList = response.data;

      } else if (response.status=='error') {
        this.toastr.error( response.data,'Error');
      }
    }, (error) => {
      console.log(error);
    });
  }
}
