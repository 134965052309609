import { Component, OnInit } from '@angular/core';
import {LoaderService} from "./loader.service";
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  loader:boolean = false;
  constructor(private loaderService:LoaderService) {
    this.loaderService.loader.subscribe((status)=>{
      this.loader = status;
    })
  }

  ngOnInit() {
  }

}
