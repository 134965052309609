import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './frontend-layout/header/header.component';
import { FooterComponent } from './frontend-layout/footer/footer.component';
import { NumbersOnlyDirective } from './directive/numbers-only.directive';
import { PhoneNumberDirective } from './directive/phone-number.directive';
import { RouterModule } from '@angular/router';
import { ValidationMessageComponent } from './validation-message/validation-message.component';
import { VariableDirective } from './directive/variable.directive';
import { CaptchaComponent } from './captcha/captcha.component';
import { OnlynumaricinputDirective } from './onlynumaricinput.directive';
import { PicturepathPipe } from './pipe/picturepath.pipe';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { MomentModule } from 'ngx-moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdvertiseComponent } from './advertise/advertise.component';
import {CatalogComponent} from './catalog/catalog.component';
import {CurrentCatalogsComponent} from './current-catalogs/current-catalogs.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
import { NgSelectModule } from '@ng-select/ng-select';
import { LoaderComponent } from './loader/loader.component';
import { ShorttextPipe } from './pipe/shorttext.pipe';
import { ContactUsPipe } from './pipe/contact-us.pipe';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NumbersOnlyDirective,
    PhoneNumberDirective,
    ValidationMessageComponent,
    VariableDirective, CaptchaComponent, OnlynumaricinputDirective, PicturepathPipe,  AdvertiseComponent,CatalogComponent,CurrentCatalogsComponent, LoaderComponent, ShorttextPipe, ContactUsPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    MomentModule,
    FormsModule, ReactiveFormsModule,
    NgSelectModule,
    TranslateModule.forRoot(
    //   {
    //     loader: {
    //         provide: TranslateLoader,
    //         useFactory: HttpLoaderFactory,
    //         deps: [HttpClient]
    //     }
    // }
    ),
  ],
  exports:[
    HeaderComponent,
    FooterComponent,
    NumbersOnlyDirective,
    PhoneNumberDirective,
    ValidationMessageComponent,
    VariableDirective,
    CaptchaComponent,
    OnlynumaricinputDirective,
    PicturepathPipe,
    MomentModule,
    AdvertiseComponent,
    CatalogComponent,CurrentCatalogsComponent,
    FormsModule, ReactiveFormsModule,
    NgSelectModule,
    LoaderComponent,
    ShorttextPipe,
    ContactUsPipe
  ]
})
export class SharedModule { }
