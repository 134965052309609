import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent implements OnChanges, OnInit {

  @Input('config') config: any = {};
  @Output() captchaCode = new EventEmitter()
  constructor() {

  }
  ngOnChanges() {
    if (this.config) {
      if (!this.config.font || !this.config.font.size) {
        this.config['font']['size'] = '40px'
      }
      if (!this.config.font || !this.config.font.family) {
        this.config['font']['family'] = 'Arial'
      }
      if (!this.config.strokeColor) {
        this.config['strokeColor'] = '#f20c6c'
      }
      if (!this.config.length) {
        this.config['length'] = 6
      }

      this.createCaptcha();
    }
  }
  createCaptcha() {
    let char = Math.random().toString(36).substring(2, this.config.length) + Math.random().toString(36).substring(2, 4);
    char = char.toUpperCase();

    setTimeout(() => {
      
      let captcahCanvas: any = document.getElementById('captcahCanvas');
      var ctx = captcahCanvas.getContext("2d");
      ctx.clearRect(0, 0, captcahCanvas.width, captcahCanvas.height);
      ctx.beginPath();
      ctx.strokeStyle = this.config.strokeColor;


      for (var i = 0; i < 150; i++) {
        ctx.moveTo(Math.random() * 300, Math.random() * 300); 
        ctx.lineTo(Math.random() * 300, Math.random() * 300);
      }
      ctx.stroke();

      ctx.font = this.config.font.size+" "+this.config.font.family;
      ctx.fillStyle = this.config.font.color;  
      ctx.fillText(char, 10, 50); 
      this.captchaCode.emit(char);


    }, 100);
  }

  ngOnInit() {
  }

}
