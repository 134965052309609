import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../_services/common.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {

  constructor(private commonService: CommonService, public translate: TranslateService) {
    this.commonService.activelang.subscribe((lang) => {
      // this language will be used as a fallback when a translation isn't found in the current language
      translate.setDefaultLang(lang);

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translate.use(lang);
    });
  }

  ngOnInit() {

  }

}
