import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CommonService } from './../../../_services/common.service';
import {Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnChanges {
  @Input('settings')settings:any = {};
  currentYear:number = (new Date()).getFullYear();
  constructor(private commonService: CommonService, private router: Router,public translate: TranslateService) {
    this.commonService.activelang.subscribe((lang) => {
      // this language will be used as a fallback when a translation isn't found in the current language
      translate.setDefaultLang(lang);

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translate.use(lang);
    });
  }

  ngOnChanges() {
  }

}
