import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { GlobalConstantService } from './global-constant.service';
import { catchError, tap, mergeMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { environment as env } from '../../environments/environment';


@Injectable({
	providedIn: 'root'
})
export class HttpRequestService {

	protected base_url: string = this.constant.API_URL;
	protected auth_url: string = 'login';
	protected social_auth_url: string = 'login/social';

	private module: any;
	public httpOptions: any;

	public vendorPerPage: Number = 10;
	public frontendPerPage: Number = 9;
	public adminPerPage: Number = 10;

	constructor(
		private http: HttpClient,
		private constant: GlobalConstantService,
		private router: Router,
	) {

		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json' ,
				 'Authorization':localStorage.getItem('token')? localStorage.getItem('token'):'',

			})
		};
	 }

	public setModule(moduleName: string) {

		this.module = null;
		if (moduleName in this.constant.apiModules) {
			this.module = this.constant.apiModules[moduleName];
			this.httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json' ,
					 'Authorization': localStorage.getItem('token')?localStorage.getItem('token'):'',

				})
			};
		}
		return this;
	}

	private getReq(url): Observable<any> {
		return this.http.get<any>(url, this.httpOptions).pipe(
			tap((data: any) => {
				return data;
			}),
			catchError(this.handleError<any>('Get one data based on id'))
		);
	}

	private postReq(url, params): Observable<any> {
		return this.http.post<any>(url, params, this.httpOptions).pipe(
			tap((data: any) => {
				return data;
			}),
			catchError(this.handleError<any>('Save data with params'))
		);
	}

	private putReq(url, params): Observable<any> {
		return this.http.put<any>(url, params, this.httpOptions).pipe(
			tap((data: any) => {
				return data;
			}),
			catchError(this.handleError<any>('Update data with params'))
		);
	}

	private deleteReq(url): Observable<any> {
		return this.http.delete<any>(url, this.httpOptions).pipe(
			tap((data: any) => {
				return data;
			}),
			catchError(this.handleError<any>('Delete data with params'))
		);
	}

	private patchReq(url, params): Observable<any> {
		return this.http.patch<any>(url, params, this.httpOptions).pipe(
			tap((data: any) => {
				return data;
			}),
			catchError(this.handleError<any>('Delete data with params'))
		);
	}

	buildRequestByMethod(methodName: string, urlParamStr: string = '', paramsObj: any = null ): Observable<any> {

		if (!this.module) {
			return throwError({
				error: {
					message: 'Module not found!'
				}
			});
		}


		const method = this.module.methods.find((el) => {
			return (el.name === methodName);
		});
		paramsObj.method = method.method;
		let url = this.module ? this.module.url : '';
		if (method) {
			url += method.url;
		}
		url += urlParamStr;

		if (method) {
			switch (method.type) {
				case 'get':
					return this.getReq(url);
				case 'post':
					return this.postReq(url, paramsObj);
				case 'delete':
					return this.deleteReq(url);
				case 'put':
					return this.putReq(url, paramsObj);
				case 'patch':
					return this.patchReq(url, paramsObj);
				default:
					return throwError({
						error: {
							message: 'Definition not found in configuration'
						}
					});
			}
		} else {
			return throwError({
				error: {
					message: 'Definition not found in configuration'
				}
			});
		}
	}

	findOne(id: string, optParams: any = null): Observable<any> {
		// console.log('call');
		let url = '';
		if (!id) {
			return throwError({
				error: {
					message: 'Id not found'
				}
			});
		}
		url += '/' + id;
		const routeParams = optParams || {};
		url += this.__objectToUrl(routeParams);

		return this.buildRequestByMethod('details', url);
		// return this.http.get<any>(url, this.httpOptions).pipe(
		// 	tap((data: any) => {
		// 		return data;
		// 	}),
		// 	catchError(this.handleError<any>('Get one data based on id'))
		// );
	}

	search(params: any): Observable<any> {
		// let url = this.module ? this.module.url : '';
		let url = '';
		url += this.__objectToUrl(params);
		return this.buildRequestByMethod('list', url);
		// return this.http.get<any>(url, this.httpOptions).pipe(
		// 	tap((data: any) => {
		// 		return data;
		// 	}),
		// 	catchError(this.handleError<any>('Get all data based on query'))
		// );
	}

	list(params: any): Observable<any> {
		let url = this.module ? this.module.url : '';
		if (this.module) {
			const method = this.module.methods.filter((element) => (element.name === 'list'));
			url += method[0].url;
		}
		url += this.__objectToUrl(params);
		return this.http.get<any>(url, this.httpOptions).pipe(
			tap((data: any) => {
				return data;
			}),
			catchError(this.handleError<any>('Get all data based on query'))
		);
	}

	post(method,params:any):Observable<any>{
		const url = '';
		return this.buildRequestByMethod(method, url, params);
	}

	create(params: any): Observable<any> {
		// const url = this.module ? this.module.url : '';
		const url = '';
		return this.buildRequestByMethod('create', url, params);
		// return this.http.post<any>(url, params, this.httpOptions).pipe(
		// 	tap((data: any) => {
		// 		return data;
		// 	}),
		// 	catchError(this.handleError<any>('Save data with params'))
		// );
	}

	update(params: any): Observable<any> {
		// let url = this.module ? this.module.url : '';
		let url = '';
		if (!('id' in params)) {
			return throwError({
				error: {
					message: 'Id not found'
				}
			});
		}
		url += '/' + params['id'];
		return this.buildRequestByMethod('update', url, params);
		// return this.http.put<any>(url, params, this.httpOptions).pipe(
		// 	tap((data: any) => {
		// 		return data;
		// 	}),
		// 	catchError(this.handleError<any>('Update data with params'))
		// );
	}

	deleteOne(params: any): Observable<any> {
		// let url = this.module ? this.module.url : '';
		let url = '';
		if (!('id' in params)) {
			return throwError({
				error: {
					message: 'Id not found'
				}
			});
		}
		url += '/' + params['id'];
		delete params.id;
		url += this.__objectToUrl(params);
		return this.buildRequestByMethod('delete', url);
		// return this.http.delete<any>(url, this.httpOptions).pipe(
		// 	tap((data: any) => {
		// 		return data;
		// 	}),
		// 	catchError(this.handleError<any>('Delete data with params'))
		// );
	}

	// isAuthinticate() {
	// 	let token = localStorage.getItem('token');
	// 	if (!token) return false;

	// 	let expirationDate = this.jwtHelper.getTokenExpirationDate(token);
	// 	let isExpired = this.jwtHelper.isTokenExpired(token);
	// 	return !isExpired || (Date.now() <= new Date(expirationDate).getTime());
	// }

	// getToken() {
	// 	return localStorage.getItem('token');
	// }

	setLastActivateTime() {
		const curTs = (new Date()).getTime();
		localStorage.setItem('last-active-time', curTs.toString());
	}

	getLastActivateTime(): Observable<any> {
		const lastTime = localStorage.getItem('last-active-time') || null;
		if (lastTime) {
			const curTs = (new Date()).getTime();
			const diff = (curTs - parseInt(lastTime, 10));
			const seconds = (diff /  1000);
			const minutes = (diff / (60 * 1000));
			const hours = (diff / (60 * 60 * 1000));
			return of({
				lastActiveTime: parseInt(lastTime, 10),
				diffObj: {
					milliseconds: diff,
					seconds: Math.round(seconds),
					minutes: Math.round(minutes),
					hours: Math.round(hours),
				}
			});
		}
		return of(lastTime);
	}

	// getUser(param = null) {
	// 	const token = localStorage.getItem('token');
	// 	if (!token) {
	// 		return false;
	// 	}
	// 	const user = this.jwtHelper.decodeToken(token).user;
	// 	return param ? (user[param] || null) : user;
	// }

	// getUserObservable(): Observable<any> {
	// 	const token = localStorage.getItem('token');
	// 	if (!token) {
	// 		return of(null);
	// 	}
	// 	const user = this.jwtHelper.decodeToken(token);
	// 	return of(user);
	// }

	// getLoggedUser(): Observable<any> {
	// 	const token = localStorage.getItem('token');
	// 	if (!token) {
	// 		return of(null);
	// 	}
	// 	const loggedObj = this.jwtHelper.decodeToken(token);
	// 	return of(loggedObj);
	// }

	// getUserRole() {
	// 	let token = localStorage.getItem('token');
	// 	if (!token) return false;
	// 	let role = this.jwtHelper.decodeToken(token).roles;
	// 	return role;
	// }


	// authinticate(credentials, loginType?) {
	// 	let url = '';
	// 	if (loginType === 'social') {
	// 		url = this.social_auth_url;
	// 	} else {
	// 		url = this.auth_url;
	// 	}

	// 	return new Promise((resolve, reject) => {
	// 		this.post(url, credentials).subscribe((response) => {
	// 			if (response['status'] === 'success') {
	// 				localStorage.setItem('token', response['token']);
	// 				localStorage.setItem('login_at', new Date().getTime().toString());
	// 				localStorage.setItem('name', this.getUser().profile.first_name ? this.getUser().profile.first_name + ' ' + this.getUser().profile.last_name : this.getUser().user_name);
	// 				localStorage.setItem('userRole', this.getUserRole()[0]);
	// 				localStorage.setItem('tutorAgreement',this.getUser().agreement_pdf);
	// 				resolve(true);
	// 			} else {
	// 				resolve(false);
	// 			}
	// 		}, (errors) => {
	// 			reject(errors);
	// 		});
	// 	});
	// }

	doLogout() {
		localStorage.clear();
		//this.router.navigate(['/login']);
		// this.router.navigate(['/']);
		window.location.href ='/';
	}

	// idealModeLogout(minute = 5) {

	// 	this.router.events.subscribe((val) => {
	// 		if (this.isAuthinticate()) {
	// 			let timePrev = parseInt(localStorage.getItem('login_at'));
	// 			let timeNow = new Date().getTime();
	// 			let timeDiff = Math.floor((timeNow - timePrev) / 1000 / 60);

	// 			if (timeDiff > minute) {
	// 				this.doLogout();
	// 				return;
	// 			}

	// 			localStorage.setItem('login_at', timeNow.toString());
	// 		}
	// 	});
	// }

	// hasPermission(name: string) {
	// 	let token = localStorage.getItem('token');
	// 	if (!token) return false;

	// 	let permissions = this.jwtHelper.decodeToken(token).permissions;
	// 	if (!permissions) return false;

	// 	if (name.indexOf('|') >= 0) {
	// 		return !!name.split('|').filter(v => permissions.indexOf(v) >= 0).length;
	// 	}

	// 	if (name.indexOf(',') >= 0) {
	// 		return (name.split(',').filter(v => permissions.indexOf(v) >= 0).length) === (name.split(',').length);
	// 	}

	// 	return permissions.indexOf(name) >= 0;
	// }

	// hasRole(name: string) {
	// 	let token = localStorage.getItem('token');
	// 	if (!token) return false;

	// 	let roles = this.jwtHelper.decodeToken(token).roles;

	// 	if (!roles) return false;

	// 	if (name.indexOf('|') >= 0) {
	// 		return !!name.split('|').filter(v => roles.indexOf(v) >= 0).length;
	// 	}

	// 	if (name.indexOf(',') >= 0) {
	// 		return (name.split(',').filter(v => roles.indexOf(v) >= 0).length) === (name.split(',').length);
	// 	}

	// 	return roles.indexOf(name) >= 0;
	// }

	// isLoggednIn() {
	// 	return this.getToken() !== null;
	// }

	private __objectToUrl(dataParams: any): string {
		let url = '';
		if (Object.keys(dataParams).length > 0) {
			Object.keys(dataParams).forEach((key, k) => {
				if (dataParams[key]) {
					url += (k === 0) ? '?' : '&';
					url += key + '=' + encodeURI(dataParams[key]);
				}
			});
		}
		return url;
	}

	public handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			throw error;
		};
	}




}
