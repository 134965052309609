import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {EncrDecrService} from './encr-decr.service';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private encDecService: EncrDecrService) { }

  private activeLangSource = new BehaviorSubject<string>(localStorage.getItem("_lang") || "EN");
	activelang = this.activeLangSource.asObservable();
	
	private isLoginStatusSource = new BehaviorSubject<boolean>(false);
	isLoginStatus = this.isLoginStatusSource.asObservable();

	private cartItemsSource = new BehaviorSubject<any>(0);
	cartItems = this.cartItemsSource.asObservable();

	private activeRouteSource = new BehaviorSubject<any>('');
	activeRoute = this.activeRouteSource.asObservable();


  
  /* Function Name : setActiveLang
	* Author : 
	* Created Date : 21-10-2019 
	* Modified Date : *
	* Purpose : to set active language
	* PARAMS : lang
	*/
	setActiveLang(lang: string) {
		localStorage.setItem("_lang", lang);
		this.activeLangSource.next(lang);
	}
	/* Function Name : setActiveRoute
	* Author : 
	* Created Date : 21-10-2019 
	* Modified Date : *
	* Purpose : to set active route
	* PARAMS : lang
	*/
	
	setActiveRoute(lang: string) {
		this.activeRouteSource.next(lang);
	}



	/* Function Name : setIsLogin
	* Author : 
	* Created Date : 05-11-2019 
	* Modified Date : *
	* Purpose : to set is login status
	* PARAMS : status
	*/
	setIsLogin(status:boolean) {
		this.isLoginStatusSource.next(status);
	}

	getLoginUserDetails() {
		if (localStorage.getItem('_user')) {
			let userData = JSON.parse(this.encDecService.get(localStorage.getItem('_user')));
			return userData;
		} else {
			this.setIsLogin(false)
			return {}; 
		}

	}

	setCartItmsCount(count) {
		if (localStorage.getItem('_user')) {
			let user = this.getLoginUserDetails();
			user['cart_count'] = count;
			let userInfo =  this.encDecService.set(JSON.stringify(user));
			localStorage.setItem('_user',userInfo);
			this.cartItemsSource.next(count);
		} else {
			let cartData= JSON.parse(this.encDecService.get(localStorage.getItem('_cartData')));
			if(cartData){
				this.cartItemsSource.next(cartData.length);
			} else {
				this.cartItemsSource.next(0);
			}
		}
	}


}
