import { Component, OnChanges, Input, OnInit } from '@angular/core';
import { CommonService } from './../../../_services/common.service';
import {Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {EncrDecrService} from './../../../_services/encr-decr.service';
import {HttpRequestService} from './../../../_services/http-request.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnChanges {
  @Input('settings')settings:any = {};
  @Input("headerData")headerData:any = null;
  public bookMenuShowStatus:Boolean= true;
  activeLang : string = ''; // to assign active language
  isLoginStatus:boolean = false;
  cartItems: number = 0;
  expandOrCollpaseIcon: string = '+';
  categoryData:Array<any> = [];
  constructor(private commonService: CommonService, private router: Router,public translate: TranslateService, private encDecService: EncrDecrService,
    private httpServices:HttpRequestService,
    private toastr: ToastrService) {
    this.commonService.activelang.subscribe((lang) => {
      // this language will be used as a fallback when a translation isn't found in the current language
      translate.setDefaultLang(lang);

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translate.use(lang);
    });
  }

  ngOnChanges() {

    // if (this.headerData) {
    //   console.log(this.headerData);
    // }
    this.activeLang = localStorage.getItem("_lang");
    if (localStorage.getItem('_user')) {
      this.commonService.setIsLogin(true);
      let userDetails = this.commonService.getLoginUserDetails();
      this.commonService.setCartItmsCount(userDetails.cart_count);
    } else {
      let cartData= JSON.parse(this.encDecService.get(localStorage.getItem('_cartData')));
      if(cartData){
          this.commonService.setCartItmsCount(cartData.length);
      } else {
        this.commonService.setCartItmsCount(0);
      }
    }
    this.commonService.isLoginStatus.subscribe((status)=>{
      this.isLoginStatus = status;
    })
    this.commonService.cartItems.subscribe((item:any)=>{

      this.cartItems = item
    })


    let data = {
      lang: localStorage.getItem('_lang')
    };
    this.httpServices.setModule('action').post('header_info',data).subscribe((response:any) => {
      if (response.status == 'success') {

        this.categoryData=response.data.category_data;

      } else {
        this.toastr.error( response.data,'Error:');
      }
    })
  }
  setActiveLang(lang) {
    localStorage.setItem('_lang', lang);
    window.location.reload();
  }
  logout() {
    // localStorage.removeItem('_user');
    localStorage.clear();
    sessionStorage.clear();
    this.commonService.setIsLogin(false);
    this.commonService.setCartItmsCount(0);
    this.router.navigate(['/']);
  }

  searchKey:any = null;
  searchByKey(event) {
    if (event.which == 13 || event.which == 1) {
      this.router.navigateByUrl("/search?key="+this.searchKey);
    }
  }


  status: boolean = false;
  clickEvent(){
    this.status = !this.status;
  }
  bookMenuShow(){
    this.bookMenuShowStatus = !this.bookMenuShowStatus;
    if (this.bookMenuShowStatus) {
      this.expandOrCollpaseIcon = '-';
    } else {
      this.expandOrCollpaseIcon = '+';
    }
  }

}
