import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSource = new BehaviorSubject<any>(false);
  loader = this.loaderSource.asObservable();
  
  constructor() { }

  setloader(status: boolean) {
		this.loaderSource.next(status);
	}
}
