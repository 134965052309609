// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let production= false;
let mode= production? '':'/nonsecure';
export const environment = {
  production: production,
	////////Local Test Config///////////
	/*api: {
		host: 'http://127.0.0.1:5000/api/1.0/nonsecure',
		port: '3000'
	},
	base_url: 'http://localhost:4200',
	api_url: 'http://127.0.0.1:5000/api/1.0/nonsecure',
	socket_url:'http://127.0.0.1:5000',
	// image_base_url: 'https://numoucenter.org/securepanel/public/uploads/',
	image_base_url: 'http://127.0.0.1:8000/public/uploads/',*/

// base_url: 'http://192.168.1.61:4201',
//////// Live build details ///////////
base_url: 'https://numoucenter.org',
 image_base_url: 'https://numoucenter.org/securepanel/public/uploads/',
api: {
	host: 'https://numoucenter.org:5040/api/1.0/'+ mode,
	port: '3000'
},
api_url: 'https://numoucenter.org:5040/api/1.0' + mode,
socket_url:'https://numoucenter:5000',

 // base_url: 'https://numoucenter.org',
	// api: {
	// 	host: 'https://numoucenter.org:5040/api/1.0/'+ mode,
	// 	port: '3000'
	// },
	// api_url: 'https://numoucenter.org:5040/api/1.0' + mode,
	// socket_url:'https://numoucenter:5000',

  // base_url: 'http://localhost:4200',
	// image_base_url: 'http://localhost:5000/securepanel/public/uploads/',
	// api: {
	// 	host: 'http://localhost:5000/api/1.0/'+ mode,
	// 	port: '3000'
	// },
	// api_url: 'http://localhost:5000/api/1.0' + mode,
	// socket_url:'http://localhost:5000',


	// base_url: 'https://numou.demoyourprojects.com',
	// image_base_url: 'https://numou.demoyourprojects.com/securepanel/public/uploads/',
	// api: {
	// 	host: 'https://numou.demoyourprojects.com:5040/api/1.0/'+ mode,
	// 	port: '5040'
	// },
  	// api_url: 'https://numou.demoyourprojects.com:5040/api/1.0'+mode,

  encrDecrKey:'bnVtb3VhcGk=',
  perPageData:2,
  soudiCountryId:2,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
