import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../../environments/environment';
@Pipe({
  name: 'picturepath'
})
export class PicturepathPipe implements PipeTransform {
  basePath: any = environment.image_base_url;
  path: any = null;
  transform(value: any, args?: any): any {
    if (args) {
      this.path = this.basePath;
      switch (args) {
        case "cms":
          this.path += 'cms_banner/';
          break;  
        case "service":
          this.path += 'service/';
          break;
        case "event_thumb":
          this.path += 'event/thumb/';
          break;
        case "event":
          this.path += 'event/';
          break;
        case "book":
          this.path += 'book/';
          break;
        case "book_thumb":
          this.path += 'book/thumb/';
          break;
        case "pdf_book":
          this.path += 'pdf_book/';
          break;  
        case "author_thumb":
          this.path += 'author/thumb/';
        break;  
        case "author":
          this.path += 'author/';
        break;
        case "video":
          this.path += 'video/';
        break;  
        case "advertise":
          this.path += 'advertise/';
        break; 
        case "catelog":
          this.path += 'catelog/';
        break; 
        case "article":
          this.path += 'article/';
        break; 
        case "article_thumb":
          this.path += 'article/thumb/';
        break;
        case "e_book_pdf":
          this.path += 'e_book_pdf/';
          break;  
        case "category":
          this.path += 'category/';
          break; 
          case "category_thumb":
            this.path += 'category/thumb';
            break;      
        
      }
      this.path += value;
    }
    return this.path;
  }

}
