import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
/*********************************
 * Directive use for make a input
 * filed allow phone number only 
 * phone number Allows :
 * 1.number and
 * 2. '(', ')', ' ','+','-'
 ********************************/
@Directive({
  selector: 'input[phoneNumber]'
})
export class PhoneNumberDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9-+() ]*/g, '');
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
