import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {CommonService} from './_services/common.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'application';
  constructor(
    public translate: TranslateService,
    private cService: CommonService,
    private http: HttpClient
  ) {
    this.cService.activelang.subscribe((lang) => {

      // this language will be used as a fallback when a translation isn't found in the current language
      translate.setDefaultLang(lang);

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translate.use(lang);
    });
  }

  ajaxCall() {
    // this.http.get(config.API_URL+'generate-token?dev=1').subscribe((responseData:any)=>{
    //   let response = (responseData);
    //   console.log(response);
    // });
    this.http.post( 'http://localhost:4200/api/1.0//test?dev=1', { name: 'Tonmoy', 'age': 20 }).subscribe((responseData: any) => {
      let response = (responseData);
      // console.log(response);
    });
  }
}
